.PrivateTabIndicator-colorSecondary-3{
    background-color: rgb(224, 237, 240) !important;
}
.container{
    display: flex;
    flex-direction: column !important;
    min-height: 90vh !important;
    border: none !important;
    gap: 10px;
    width: 100% !important;
}

.header{
    background-color: white !important;
    height: 100px;
}
.monthTitle{
    display: flex;
    justify-content: space-between;
    padding: 10px !important;
}
.monthName{
    display: flex;
    align-items: center;
    color: #325962;
    font-weight: 600;
    font-size: 20px;
    gap:5px;
    cursor: pointer;
}
.month{
    display: flex;
    justify-content: space-between  !important;
    padding: 10px !important;
}
.monthDiv{
   display: flex;
   justify-content: center !important;
   flex-direction:column;
   align-items: center !important;
   font-size: 16px !important;
   color: #1E293B !important;
}
.monthDuration{
  color: #64748B;
  font-weight: 400;
  size: 12px !important;
}
.eventsCard{
    display: flex;
    flex-direction: column !important;
    height: 100vh;
    overflow-y: auto;
    width: 136%;
}
.eventsCard::-webkit-scrollbar {
    width: 2; 
}

.eventsCard::-webkit-scrollbar-track {
    background: transparent; 
    border-radius: 10px;
}

.eventsCard::-webkit-scrollbar-thumb {
    background:transparent;
    border-radius: 10px;
}

.eventsCard::-webkit-scrollbar-thumb:hover {
    background: transparent; 
}

.yellowDot{
    height: 10px;
    width: 10px;
    background-color: #F59E0B;
    border-radius:100%;
}
.greenDot{
    height: 10px;
    width: 10px;
    background-color: #34D399;
    border-radius:100%;
}
.yellowDot2{
    height: 10px;
    width: 10px;
    background-color: #F59E0B;
    border-radius:100%;
}
.cardContent{
    min-width:607px !important;
    max-width: none !important;
    background-color: white;
    border-radius: 16px !important;
    border-top-right-radius: 1px solid blue ;
    /* height: 50px; */
    padding: 5px 10px;
    align-items: center;
    display: flex;
    gap:10px;
    cursor: pointer;
    justify-content: space-between;
    border-left: 4px solid #60A5FA;
}

.rbc-month-view{
    display: none !important;
}
.roomListViewCard{
        display: flex;
        flex-direction: column !important;
        padding-right:10px;
        height: 100vh;
        overflow-y: auto;
        width: 120%;
}
.roomListCard{
    min-width:400px !important;
    /* max-width:400px !important;     */
    background-color: white;
    border-radius: 16px !important;
    /* height: 50px; */
    padding: 1px 10px 8px 10px;
    align-items: center;
    flex-direction: column !important;
    display: flex;
    gap:10px;
    justify-content: space-between;
    border-left: 4px solid #FBBF24;
    cursor: pointer;
}
.roomBottomPart{
    display: flex;
    justify-content: space-between;
    width:100%;
    margin-top: -12px;
}
.roomBlockName{
    display: flex;
    flex-direction: column !important;
    background-color: white !important;
    height: 100px !important;
    width: 200px;
    align-items: center;
    justify-content: center !important;
    color: #334155;
    font-size: 14px !important;
    font-weight: 600 !important;
    border-top-left-radius: 20px;
}
.RoomNameColor{
    display: flex;
    background-color: white;
    align-items: center;
    flex-wrap: wrap;
    height: 96px !important;
    font-weight: 400;
    color:'#475569';
    font-size:14;
    font-family: 'Poppins';
    color: #475569;
    padding-left: 20;
}
.RoomNameColor1{
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100% !important;
    font-weight: 600;
    min-height: 150px !important;
    font-weight: 400;
    color:'#475569';
    font-size:14;
    font-family: 'Poppins';
    text-align: center;
    color: #475569;
}

.roomListViewCard::-webkit-scrollbar {
    width: 2; 
}

.roomListViewCard::-webkit-scrollbar-track {
    background: transparent; 
    border-radius: 10px;
}

.roomListViewCard::-webkit-scrollbar-thumb {
    background:transparent;
    border-radius: 10px;
}

.roomListViewCard::-webkit-scrollbar-thumb:hover {
    background: transparent; 
}